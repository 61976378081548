export const avtarGroupAvailble= [
"https://res.zaloapp.com/pc/avt_group/1_family.jpg",
"https://res.zaloapp.com/pc/avt_group/2_family.jpg",
"https://res.zaloapp.com/pc/avt_group/3_family.jpg",
"https://res.zaloapp.com/pc/avt_group/4_work.jpg",
"https://res.zaloapp.com/pc/avt_group/5_work.jpg",
"https://res.zaloapp.com/pc/avt_group/6_work.jpg",
"https://res.zaloapp.com/pc/avt_group/9_friends.jpg",
"https://res.zaloapp.com/pc/avt_group/10_school.jpg",
"https://res.zaloapp.com/pc/avt_group/11_school.jpg",
"https://res.zaloapp.com/pc/avt_group/12_school.jpg",
];